import React from "react";
import EQBrandLogo from "../../assets/img/ecosense-brand-logo-negative-white.png";
import EQBrand from "../../assets/img/ecosense-brandmark-positive.png";
import EQLogo from "../../assets/img/EQ200_Logo_wh.png";
import bg from "../../assets/img/bg-half.png";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import "../../assets/css/auth.css";
import "../../assets/css/common.css";
import { validEmailRegex, validPwdRegex } from "../../utils/AuthValidation";
import * as types from "../../constants";
import { Auth } from "aws-amplify";
import InputBase from "@material-ui/core/InputBase";
import {
  Button,
  Paper,
  TextField as MuiTextField,
  FormControl as MuiFormControl,
  Typography,
  Box,
  CircularProgress,
  Grid,
  useTheme,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import _ from "lodash";
import { TEST_EMAIL } from "../../constants/testAccount";
import { useDispatch } from "react-redux";

const Alert = styled(MuiAlert)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("xs")} {
    padding-top: ${(props) => props.theme.spacing(10)}px;
    padding-bottom: ${(props) => props.theme.spacing(10)}px;
    padding-left: ${(props) => props.theme.spacing(15)}px;
    padding-right: ${(props) => props.theme.spacing(15)}px;
  }
`;

function SignIn() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const [requesting, setRequesting] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [pwdValues, setPwdValues] = React.useState({
    currentPassword: "",
    showCurrentPassword: false,
  });

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePwdChange = (prop) => (event) => {
    setPwdValues({ ...pwdValues, [prop]: event.target.value });
  };

  const handleClickShowCurrentPassword = () => {
    setPwdValues({
      ...pwdValues,
      showCurrentPassword: !pwdValues.showCurrentPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validate = (values) => {
    const errors = {};

    if (!email) {
      errors.email = "Required";
    } else if (!validEmailRegex.test(email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (!pwdValues.currentPassword) {
      errors.currentPassword = "Required";
    } else if (!validPwdRegex.test(pwdValues.currentPassword)) {
      errors.currentPassword =
        "It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.";
    }

    return errors;
  };

  return (
    <Grid
      container
      direction="row"
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
      }}
    >
      {matches && (
        <Grid
          xs={6}
          item
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: theme.palette.primary.main,
            width: "100vw",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid item style={{ marginTop: "auto" }}>
            <img
              style={{
                width: "100%",
                height: "80px",
                objectFit: "contain",
              }}
              src={EQLogo}
              alt="ecosense logo"
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h1"
              style={{
                marginTop: 26,
                textAlign: "center",
                color: "white",
                fontFamily: "avenir",
                fontWeight: "500",
                width: "100%",
              }}
            >
              Radon Testing with Ecosense Solution Fast and Accurately
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: "auto" }}>
            <img
              style={{
                width: "100%",
                height: "200px",
                objectFit: "contain",
              }}
              src={EQBrandLogo}
              alt="ecosense brand logo"
            />
          </Grid>
        </Grid>
      )}
      {requesting ? (
        <Grid
          item
          xs={matches ? 6 : 12}
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Box display="flex" flexDirection={"column"}>
            <div className="progress">
              <CircularProgress />
            </div>
            <div className="s20"></div>
            <Typography variant={"body1"}>Signing In ..</Typography>
          </Box>
        </Grid>
      ) : (
        <Grid
          item
          xs={matches ? 6 : 12}
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Wrapper>
            <Helmet title="Sign In" />
            <div>
              <div className="s15"></div>
              <div align="center">
                <img
                  style={{
                    width: "200px",
                    height: "150px",
                    objectFit: "contain",
                  }}
                  src={EQBrand}
                  alt="ecosense logo"
                />
              </div>
              <div align="center">
                <span className="signin-title-text">
                  Sign in to ecoqube
                </span>
              </div>
              <div className="s20"></div>
              <div align="center">
                {/*<span*/}
                {/*  className="account-check-text"*/}
                {/*  style={{ color: theme.palette.primary.main }}*/}
                {/*>*/}
                {/*  Don't have an account?{" "}*/}
                {/*  <Link*/}
                {/*    style={{ color: theme.palette.primary.main }}*/}
                {/*    className="account-check-link"*/}
                {/*    to="/auth/sign-up"*/}
                {/*  >*/}
                {/*    SIGN UP*/}
                {/*  </Link>*/}
                {/*</span>*/}
              </div>
              <div className="s30"></div>
            </div>
            <Formik
              initialValues={{
                email: "",
                submit: false,
              }}
              validate={validate}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                dispatch({ type: types.RESET_PROJECT_LIST });

                setErrorMsg("");
                const trimmedEmail = email.trim().toLowerCase();
                try {
                  setRequesting(true);

                  const user = await Auth.signIn(
                    trimmedEmail,
                    pwdValues.currentPassword
                  );
                  localStorage.setItem("0xuserId", user.attributes.name);
                  if (TEST_EMAIL) {
                    localStorage.setItem("0xaccountId", TEST_EMAIL);
                  } else {
                    localStorage.setItem("0xaccountId", trimmedEmail);
                  }
                  localStorage.setItem("accessSource", "web");

                  const group = _.get(user, "attributes.custom:group");

                  if (group && (group !== "owner" || group === "mitigator")) {
                    throw new Error("NotOwnerUserException");
                  }

                  if (location && location.invoiceNo) {
                    const invoiceNo = location.invoiceNo;
                    delete location.invoiceNo;
                    history.push("/subscriptions/detail?" + invoiceNo);
                  } else {
                    history.push({
                      pathname: "/reports",
                      state: {
                        email: trimmedEmail,
                        name: localStorage.getItem("0xuserId"),
                      },
                    });
                  }
                } catch (error) {
                  let message = error.message || "Something went wrong";
                  if (error.code === "UserNotConfirmedException") {
                    message = "Account not verified yet";
                    history.push({
                      pathname: "/auth/passcode-required",
                      state: {
                        from: "/auth/sign-in",
                        email: trimmedEmail,
                      },
                    });
                  } else if (error.code === "PasswordResetRequiredException") {
                    message = "Existing user found. Please reset your password";
                  } else if (error.code === "NotAuthorizedException") {
                    message =
                      "User and password don't match or user doesn't exist";
                  } else if (error.code === "UserNotFoundException") {
                    message =
                      "Email and password don't match or user doesn't exist";
                  } else if (error.message === "NotOwnerUserException") {
                    message =
                      "The \"My Radon\" report service is accessible only to regular user accounts. Mitigator or guest accounts are not permitted to access it. Please contact us.";
                  }

                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setErrorMsg(message);
                  setSubmitting(false);
                }
                setRequesting(false);
              }}
            >
              {({ errors, handleSubmit, isSubmitting, touched, dirty }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {errorMsg && (
                    <Alert mt={2} mb={1} severity="warning">
                      {errorMsg}
                    </Alert>
                  )}
                  <TextField
                    type="email"
                    name="email"
                    label="Email Address"
                    value={email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onChange={handleEmailChange}
                    my={3}
                  />
                  <FormControl fullWidth my={0}>
                    <InputLabel
                      htmlFor="standard-current-password"
                      error={errors.currentPassword ? true : false}
                    >
                      Password
                    </InputLabel>
                    <Input
                      id="standard-current-password"
                      autoComplete="new-password"
                      aria-describedby="current-password-helper"
                      type={pwdValues.showCurrentPassword ? "text" : "password"}
                      value={pwdValues.currentPassword}
                      onChange={handlePwdChange("currentPassword")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCurrentPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {pwdValues.showCurrentPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.currentPassword && (
                      <FormHelperText id="current-password-helper" error={true}>
                        {errors.currentPassword}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <InputBase disabled inputProps={{ "aria-label": "naked" }} />
                  <div className="s20"></div>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    classes={{ root: "button", label: "button-label" }}
                    style={{ backgroundColor: theme.palette.primary.main }}
                  >
                    SIGN IN
                  </Button>
                  <div
                    className="s10"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Link
                      to="/auth/forgot-password"
                      style={{
                        color: theme.palette.primary.main,
                        textDecoration: "none",
                      }}
                    >
                      Forgot password?
                    </Link>
                  </div>
                </form>
              )}
            </Formik>
          </Wrapper>
        </Grid>
      )}
    </Grid>
  );
}

export default SignIn;
