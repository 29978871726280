import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import "../../assets/css/auth.css";
import "../../assets/css/common.css";
import { Typography, Box, CircularProgress } from "@material-ui/core";

function IntegrateWithApp() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  // let param = {
  //   email: '',
  //   accessToken: ''
  // }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = decodeURIComponent(params.get("email"));
    const reportId = decodeURIComponent(params.get("reportId"));
    const accessToken = decodeURIComponent(params.get("accessToken"));
    // param.email = email;
    // param.accessToken = accessToken;
    localStorage.setItem("0xaccountId", email);
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("accessSource", "app");

    //alert(reportId);
    if (accessToken) {
      history.replace('/report', { email, reportId });
    }
  }, []);

  return loading ? (
    <>
      <Box display="flex" flexDirection={"column"}>
        <div className="progress">
          <CircularProgress />
        </div>
        <div className="s20"></div>
        <Typography variant={"body1"}>Loading ..</Typography>
      </Box>
    </>
  ) : (
    <></>
  );
}

export default IntegrateWithApp;
