import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import _ from "lodash";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { Divider as MuiDivider, Typography, Card, CardContent, Button } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import MyRadonLineChart from "./MyRadonLineChart";
import MyRadonInterface from "../../integration/internal/MyRadon/MyRadonInterface";
import { SUCCESS } from "../../constants/resultCodes";
import { enqueueSnackbar } from "notistack";
import moment from "moment-timezone";
import {
  DISCLAIMER,
  mitigationSystem,
  singleBuildingType,
  structureType,
  testFloor,
  TIMEZONE_MAP
} from "../../constants/values";
import Loading from "../components/Loading";
import Space from "../../components/Space";
import { ExpandMore, ExpandLess } from "@material-ui/icons";

import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";  // recharts에서 필요한 컴포넌트를 가져옵니다.


const TableContainer = styled.div`
  max-height: ${({ expanded }) => (expanded ? "none" : "180px")};
  overflow: hidden;
`;

const ExpandButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
`;

const Divider = styled(MuiDivider)(spacing);

const Container = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 0;
`;

const SectionTitle = styled(Typography)`
  font-weight: bold;
  margin-bottom: 8px;
`;

const RecommendTitle = styled(Typography)`
  font-weight: bold;
  margin-bottom: 8px;
  color: #D65A5A; /* 또는 #D32F2F */
`;

const BulletPoint = styled(Typography)`
  margin-left: 16px;
  margin-bottom: 4px;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid lightgray;
`;

const KeyText = styled(Typography)`
  // font-weight: bold;
`;

const ValueText = styled(Typography)`
  text-align: right;
`;

const CustomList = styled.ul`
  margin-top: 4px;
  padding-left: 0;
  list-style: none;
`;

const CustomListItem = styled.li`
  position: relative;
  padding-left: 20px; /* 텍스트 위치 조정 */
  padding-bottom: 4px;
  padding-top: 0px;
`;

const CustomBullet = styled.span`
  position: absolute;
  left: 5px; /* BulletPoint 왼쪽 이동 */
  top: 6px;
  width: 5px;
  height: 5px;
  background-color: gray;
  border-radius: 50%;
`;

const CustomText = styled.span`
  color: #4a4a4a; /* 어두운 회색 */
  font-size: 14px;
`;

function Report() {
  const location = useLocation();
  const [disclaimer, setDisclaimer] = useState(DISCLAIMER);
  const [summary, setSummary] = useState({
    'deviceName': '',
    'avg72Hour': '',
    'location': '',
    'mitigationInstalled': '',
    'headline': '',
    'details': []
  });

  const [measurement, setMeasurement] = useState({
    'dateTime': '',
    'location': '',
    'highest': '',
    'average': '',
    'lowest': '',
  });

  const [above, setAbove] = useState();
  const [compare, setCompare] = useState({
    'testAvg': '',
    'stateAvg': ''
  });
  const [graphData, setGraphData] = useState([]);

  const [pieData, setPieData] = useState({
    lowCount: 0,
    mediumCount: 0,
    highCount: 0,
    lowPercentage: '0.0',
    mediumPercentage: '0.0',
    highPercentage: '0.0',
  });

  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  let { email, reportId } = location.state || {};
  //alert('email = ' + email + ', reportId = ' + reportId);

  // TEST DATA
  if (!email) {
    email = localStorage.getItem("0xaccountId");
  }
  //
  // email = 'seungjae.jung@gmail.com'
  // if (!reportId) {
  //   reportId = "MRR-1742012457";
  // }

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const loadReportData = async () => {
      try {
        setLoading(true); // 데이터 로딩 시작
        let res = await MyRadonInterface.getMyRadonDetails(email, reportId);
        console.log('res = ' + JSON.stringify(res));

        let data = _.get(res, "data", []);
        if (!data || data.length === 0) {
          throw new Error("No data error");
          setLoading(false);
          return;
        }

        /* Common */
        const BqToPci = (Bq) => (Bq * 0.027).toFixed(1); // Bq -> pCi/L 변환 공식

        /* Set Time Zone */
        const masterInfo = _.get(data[0], "master", {});
        const timezone = (masterInfo && masterInfo.location && masterInfo.location.state) ? TIMEZONE_MAP[masterInfo.location.state] : "America/New_York"; // Default timezone
        // alert('masterInfo.location.state = ' + masterInfo.location.state + ', timezone = ' + timezone);
        /* Set Summary Data */
        const deviceName = _.get(data[0], "device_name", '');
        let avg72Hour = _.get(data[0], "analysis_radon.avg", '');
        let locationFloor = _.get(data[0], "master.placement.location_floor", '');
        let mitigationInstalled = _.get(data[0], "master.placement.mitigation_installed", '');
        let headline = _.get(data[0], "recommendations.headline", '');
        let details = _.get(data[0], "recommendations.details", []);

        // 1. Convert avg72Hour (Bq) to pCi/L and round to one decimal place
        const avg72HourInPCiL = avg72Hour ? BqToPci(avg72Hour) : '';

        // 2. Convert location code to structureType string
        const locationStr = testFloor[locationFloor] || locationFloor; // Defaults to the location code if not found in structureType

        // 3. Convert mitigationInstalled value to appropriate string
        const mitigationInstalledStr = mitigationSystem[mitigationInstalled] || 'N/A'; // Defaults to 'N/A' if the value is undefined or invalid

        const summaryData = {
          'deviceName': deviceName,
          'avg72Hour': avg72HourInPCiL,  // pCi/L value with 1 decimal place
          'location': locationStr,  // Converted location type
          'mitigationInstalled': mitigationInstalledStr,  // Converted mitigation status
          'headline': headline,
          'details': details,
        };

        /* Set Measurement Details */
        // 타임존 변경 및 포맷 수정
        let measurementStartTime = _.get(data[0], "analysis_radon.duration.start", '')/1000;
        let measurementEndTime = _.get(data[0], "analysis_radon.duration.end", '')/1000;

        // Start time과 End time을 timezone에 맞게 포맷 변경
        if (measurementStartTime) {
          measurementStartTime = moment(measurementStartTime).tz(timezone).format('MM/DD/YY hh:mm a');
        }

        if (measurementEndTime) {
          measurementEndTime = moment(measurementEndTime).tz(timezone).format('MM/DD/YY hh:mm a');
        }

        // 주소 정보
        const street = _.get(data[0], "master.location.street", '');
        const city = _.get(data[0], "master.location.city", '');
        const state = _.get(data[0], "master.location.state", '');

        // 주소 처리 로직
        let address = '';
        if (street && city) {
          address = street + ', ' + city + ', ' + state;
        } else if (street && !city) {
          address = street + ', ' + state;
        } else if (!street && city) {
          address = city + ', ' + state;
        } else if (!street && !city && state) {
          address = state;
        }

        // Radon 수치 정보
        const highest = _.get(data[0], "analysis_radon.highest_radon.radon_level", '');
        const lowest = _.get(data[0], "analysis_radon.lowest_radon.radon_level", '');

        const highestInPci = highest ? BqToPci(highest) : '';
        const lowestInPci = lowest ? BqToPci(lowest) : '';

        const measurementData = {
          'dateTime': measurementStartTime + ' - ' + measurementEndTime,
          'location': address,
          'highest': highestInPci,
          'average': avg72HourInPCiL,
          'lowest': lowestInPci,
        }

        /* Set Above */
        let aboveData = _.get(data[0], "analysis_radon.high_readings", []);
        const transformedAboveData = aboveData.map((entry) => {
          const radonLevelInPci = BqToPci(entry.radon_level); // Bq -> pCi/L 변환
          const formattedTime = moment(entry.timestamp).tz(timezone).format('MM/DD/YY hh:mm a'); // 시간 포맷 변경

          return {
            date: formattedTime.split(' ')[0], // 날짜 부분
            time: formattedTime.split(' ')[1] + ' ' + formattedTime.split(' ')[2], // 시간 부분
            value: `${radonLevelInPci} pCi/L`, // 변환된 라돈 수치
          };
        });

        /* Set Compare */
        let stateAvg = _.get(data[0], "community_radon.state_avg", '');
        const stateAvgInPci = stateAvg ? BqToPci(stateAvg) : '';
        const compareData = {
          'testAvg': avg72HourInPCiL,
          'stateAvg': stateAvgInPci
        }

        /* Set LineChart */
        const transformedGraphData = data
          .flatMap((report) => {
            return report.collected.map((entry) => {
              try {
                const {
                  weather = {}, // default empty object
                  radon_level = null, // default to null if radon_level is not present
                  timestamp = 0, // default to 0
                  device_location = {}, // default empty object
                } = entry;

                const { dt, tmp, hmd, wnd_sp, prs, main } = weather;
                const { state } = device_location;

                // 🕒 Timezone conversion
                const localTime = moment.unix(Math.floor(timestamp / 1e6)).tz(timezone);

                // Assigning default values if the variables are null or undefined
                const radonValue = radon_level !== null ? parseFloat((radon_level / 37).toFixed(2)) : null;
                const temperature = tmp !== null && tmp !== undefined ? parseFloat(tmp) : null;
                const humidity = hmd !== null && hmd !== undefined ? parseFloat(hmd) : null;
                const windSpeed = wnd_sp !== null && wnd_sp !== undefined ? parseFloat(wnd_sp) : null;
                const pressure = prs !== null && prs !== undefined ? parseFloat(prs) : null;

                // If any value is null, skip this data entry
                if (radonValue === null || temperature === null || humidity === null || windSpeed === null || pressure === null) {
                  // return null; // Skip the entry if any value is null
                }

                return {
                  name: localTime.format("MM/DD/YYYY hh:mm A"),
                  time: localTime.format("hh:mm"),
                  period: localTime.format("A"),
                  value1: radonValue, // Bq → pCi/L conversion
                  value2: temperature, // Temperature (℉)
                  value3: humidity, // Humidity (%)
                  value4: windSpeed, // Wind speed (m/s)
                  value5: pressure, // Pressure (inHg)
                  weather: main, // Weather description
                  timestamp: timestamp // 원래 타임스탬프를 추가하여 정렬에 사용
                };
              } catch (error) {
                console.error("Error processing entry:", error);
                return null; // Return null if there's an error
              }
            }).filter((entry) => entry !== null); // Filter out null entries
          })
          .sort((a, b) => a.timestamp - b.timestamp); // 타임스탬프 기준 오름차순 정렬

        let pieChartData = pieData;
        if (transformedGraphData.length > 0) {
          let low = 0, medium = 0, high = 0;

          transformedGraphData.forEach(entry => {
            const val = entry.value1;
            if (val < 2.7) low++;
            else if (val >= 2.7 && val <= 3.9) medium++;
            else if (val >= 4) high++;
          });

          const total = low + medium + high;
          const calcPercentage = (count) =>
            total > 0 ? ((count / total) * 100).toFixed(1) : '0.0';

          pieChartData = {
            lowCount: low,
            mediumCount: medium,
            highCount: high,
            lowPercentage: calcPercentage(low),
            mediumPercentage: calcPercentage(medium),
            highPercentage: calcPercentage(high),
          }
        }

        // Rendering
        if (res?.resultcode === SUCCESS) {
          setSummary(summaryData);
          setMeasurement(measurementData);
          setAbove(transformedAboveData);
          setCompare(compareData);
          setGraphData(transformedGraphData);
          setPieData(pieChartData);
        } else {
          throw new Error("API error");
        }

      } catch (error) {
        enqueueSnackbar("Error fetching report details! error: " + error, { variant: "error" });
      } finally {
        setLoading(false); // 데이터 로딩 종료
      }
    };

    loadReportData();
  }, [email, reportId]); // 의존성 배열에 email과 reportId만 넣음

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      {/* Check if summary.avg72Hour exists (non-null, non-undefined) */}
      {summary.avg72Hour !== undefined && summary.avg72Hour !== null && summary.avg72Hour !== '' ? (
        <>
          {/* Render original content when avg72Hour exists */}
          <Card style={{ marginLeft: 2, marginRight: 2 }}>
            <CardContent>
              <SectionTitle variant="h6">Disclaimer</SectionTitle>
              <Typography variant="body2">
                {disclaimer}
              </Typography>
            </CardContent>
          </Card>

          <Card style={{ marginLeft: 2, marginRight: 2, marginTop: 16 }}>
            <CardContent>
              <RecommendTitle
                variant="h6"
                style={{
                  color: summary.avg72Hour < 2.7 ? "#4CAF50" :
                    summary.avg72Hour <= 3.9 ? "#FFA726" :
                      "#D32F2F"
                }}
              >
                {summary.avg72Hour < 2.7 ? "No Action Required" :
                  summary.avg72Hour <= 3.9 ? "Consider Fixing Home" :
                    "Recommend Fixing Home"}
              </RecommendTitle>
              <StyledRow>
                <KeyText variant="body2">Device Name</KeyText>
                <ValueText variant="body2">{summary.deviceName}</ValueText>
              </StyledRow>
              <StyledRow>
                <KeyText variant="body2">72-Hr Radon Average</KeyText>
                <ValueText variant="body2">{summary.avg72Hour} pCi/L</ValueText>
              </StyledRow>
              <StyledRow>
                <KeyText variant="body2">Location Floor</KeyText>
                <ValueText variant="body2">{summary.location}</ValueText>
              </StyledRow>
              <StyledRow>
                <KeyText variant="body2">Mitigation System Installed</KeyText>
                <ValueText variant="body2">{summary.mitigationInstalled}</ValueText>
              </StyledRow>
              <Space height={"20px"} />
              <CustomList>
                {summary.details.map((detail, index) => (
                  <CustomListItem key={index}>
                    <CustomBullet />
                    <CustomText>{detail}</CustomText>
                  </CustomListItem>
                ))}
              </CustomList>
            </CardContent>
          </Card>
          <Card style={{ marginLeft: 2, marginRight: 2, marginTop: 16 }}>
            <CardContent>
              <SectionTitle variant="h6">Measurement Details</SectionTitle>
              <StyledRow>
                <KeyText variant="body2">Date/Time</KeyText>
                <ValueText variant="body2">{measurement.dateTime}</ValueText>
              </StyledRow>
              <StyledRow>
                <KeyText variant="body2">Location</KeyText>
                <ValueText variant="body2">{measurement.location}</ValueText>
              </StyledRow>
              <StyledRow>
                <KeyText variant="body2">Highest</KeyText>
                <ValueText variant="body2">{measurement.highest} pCi/L</ValueText>
              </StyledRow>
              <StyledRow>
                <KeyText variant="body2">Average</KeyText>
                <ValueText variant="body2">{measurement.average} pCi/L</ValueText>
              </StyledRow>
              <StyledRow>
                <KeyText variant="body2">Lowest</KeyText>
                <ValueText variant="body2">{measurement.lowest} pCi/L</ValueText>
              </StyledRow>
            </CardContent>
            <MyRadonLineChart data={graphData} />
          </Card>
          <Card style={{ marginLeft: 2, marginRight: 2, marginTop: 16 }}>
            <CardContent>
              <SectionTitle variant="h6">Date/Time Above 4pCi/L</SectionTitle>
              <Space height={"10px"} />
              {(above && above.length > 0) ? (
                <>
                  <TableContainer expanded={expanded}>
                    {above.map((entry, index) => (
                      <StyledRow key={index}>
                        <KeyText variant="body2">{entry.date} {entry.time}</KeyText>
                        <ValueText variant="body2">{entry.value}</ValueText>
                      </StyledRow>
                    ))}
                  </TableContainer>
                  <ExpandButton onClick={() => setExpanded(!expanded)}>
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                  </ExpandButton>
                </>
              ) : (
                <KeyText variant="body2" style={{ color: "#757575", textAlign: "center" }}>
                  No data available
                </KeyText>
              )}
            </CardContent>
          </Card>

          <Card style={{ marginLeft: 2, marginRight: 2, marginTop: 16 }}>
            <CardContent>
              <SectionTitle variant="h6">My Radon vs State (EPA)</SectionTitle>
              <StyledRow>
                <KeyText variant="body2">72 hour avg</KeyText>
                <ValueText variant="body2">{compare.testAvg} pCi/L</ValueText>
              </StyledRow>
              <StyledRow>
                <KeyText variant="body2">State radon avg</KeyText>
                <ValueText variant="body2">{compare.stateAvg} pCi/L</ValueText>
              </StyledRow>
            </CardContent>
          </Card>

          <Card style={{ marginLeft: 2, marginRight: 2, marginTop: 16 }}>
            <CardContent style={{ marginBottom: 0, paddingBottom: 0}}>
              <SectionTitle variant="h6" style={{ marginBottom: 0, paddingBottom: 0}} >Radon Trends</SectionTitle>
            </CardContent>
            <CardContent style={{ textAlign: 'center', paddingTop: 0, marginTop: 0 }}>
              <div
                style={{
                  display: 'flex',
                  gap: '20px',
                  alignItems: 'flex-start', // 세로 방향으로 위쪽에 붙게 설정
                  flexWrap: 'wrap', // 화면이 좁을 때 차트와 텍스트가 줄바꿈 되도록 함
                  justifyContent: 'center', // 차트와 텍스트를 중앙에 배치
                  // backgroundColor: 'red',
                  marginTop: 0, // 추가 여백 제거
                }}
              >
                <div
                  style={{
                    width: '50%',
                    minWidth: 250, // 파이차트가 최소 250px 크기를 가지도록 함
                    display: 'flex',
                    justifyContent: 'center', // 파이차트를 중앙 정렬
                    marginTop: 0, // 파이차트와 위쪽 여백을 0으로 설정
                  }}
                >
                  <PieChart width={250} height={250}>
                    <Pie
                      data={[
                        { name: 'Low', value: pieData.lowCount },
                        { name: 'Medium', value: pieData.mediumCount },
                        { name: 'High', value: pieData.highCount }
                      ]}
                      dataKey="value"
                      nameKey="name"
                      cx="50%" cy="50%" outerRadius={80} fill="#8884d8"
                      label={false} // 레이블을 비활성화하여 단위가 표시되지 않도록 함
                    >
                      <Cell fill="#4CAF50" /> {/* Low */}
                      <Cell fill="#FFA726" /> {/* Medium */}
                      <Cell fill="#D32F2F" /> {/* High */}
                    </Pie>
                    <Legend />
                  </PieChart>
                </div>
                <div
                  style={{
                    width: '50%',
                    minWidth: 200, // 최소 너비를 설정하여 글씨가 너무 좁지 않게 함
                  }}
                >
                  <div style={{ marginBottom: 16 }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12, justifyContent: 'center' }}>
                      <div
                        style={{
                          width: 16,
                          height: 16,
                          backgroundColor: '#D32F2F', // Low 색상
                          borderRadius: '50%',
                          marginRight: 10,
                        }}
                      ></div>
                      <Typography variant="body2">≥ 4 pCi/L</Typography>
                      <Typography variant="body2" style={{ marginLeft: 'auto', fontWeight: 500, paddingRight: 20 }}>
                        {pieData.highPercentage}%
                      </Typography>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12, justifyContent: 'center' }}>
                      <div
                        style={{
                          width: 16,
                          height: 16,
                          backgroundColor: '#FFA726', // Medium 색상
                          borderRadius: '50%',
                          marginRight: 10,
                        }}
                      ></div>
                      <Typography variant="body2">2.7-3.9 pCi/L</Typography>
                      <Typography variant="body2" style={{ marginLeft: 'auto', fontWeight: 500, paddingRight: 20 }}>
                        {pieData.mediumPercentage}%
                      </Typography>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16, justifyContent: 'center' }}>
                      <div
                        style={{
                          width: 16,
                          height: 16,
                          backgroundColor: '#4CAF50', // High 색상
                          borderRadius: '50%',
                          marginRight: 10,
                        }}
                      ></div>
                      <Typography variant="body2">&lt; 2.7 pCi/L</Typography>
                      <Typography variant="body2" style={{ marginLeft: 'auto', fontWeight: 500, paddingRight: 20 }}>
                        {pieData.lowPercentage}%
                      </Typography>
                    </div>
                  </div>
                  <Typography
                    variant="body2"
                    style={{
                      marginTop: 12,
                      color: '#666',
                      fontSize: 12,
                    }}
                  >
                    EPA Action Guideline: 4 pCi/L
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
        </>
      ) : (
        <Card style={{ marginLeft: 2, marginRight: 2, marginTop: 16 }}>
          <CardContent>
            <Typography variant="h6" style={{ textAlign: 'center', color: '#808080', fontSize: '13px' }}>
              {reportId
                ? `An issue occurred while loading the report data using Report ID (${reportId}). Please try again.`
                : "There's no passed Report ID. Please try again."
              }
            </Typography>
          </CardContent>
        </Card>
      )}
    </Container>
  );
}

export default Report;

