import { NETWORK_LOG } from "../../../constants/envConstant";
import { CRITICAL_ACTION_TIMEOUT } from "../../../constants/values";
import { apiEndPoint } from "../../../env";
import { AuthUtil } from "../../../views/auth/AuthUtil";

const MyRadonInterface = {
  getMyRadonList: async function (email) {
    let requestUrl = apiEndPoint + "/premium/insight/reports";
    const controller = new AbortController();
    let token = await AuthUtil.getAuthToken();
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
      signal: controller.signal,
      credentials: "include",
      cache: "no-cache",
    };

    const timeoutId = setTimeout(
      () => controller.abort(),
      CRITICAL_ACTION_TIMEOUT
    );
    let res = await fetch(requestUrl + "?email=" + email, requestOptions)
      .then((res) => {
        return res.text().then((text) => {
          const data = text && JSON.parse(text);
          return data;
        });
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          return "fetch() request was aborted, hit CTRL-R to try again.";
        }
        console.log(err);
      });
    return res;
  },

  getMyRadonDetails: async function (email, reportId) {
    let requestUrl = apiEndPoint + "/premium/insight/reports";
    const controller = new AbortController();
    let token = await AuthUtil.getAuthToken();
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
      signal: controller.signal,
      credentials: "include",
    };
    const timeoutId = setTimeout(
      () => controller.abort(),
      CRITICAL_ACTION_TIMEOUT
    );

    console.log('param = ' + requestUrl + "?email=" + email + "&reportId=" + reportId + ':::' + JSON.stringify(requestOptions));

    let res = await fetch(
      requestUrl + "?email=" + email + "&reportId=" + reportId,
      requestOptions
    )
      .then((res) => {
        // console.log('res = ' + JSON.stringify(res));
        return res.text().then((text) => {
          const data = text && JSON.parse(text);
          return data;
        });
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          return "fetch() request was aborted, hit CTRL-R to try again.";
        }
        console.log(err);
      });
    return res;
  },

  deleteMyRadon: async (pId, tId) => {

  },

  log: function (msg, flag) {
    if (NETWORK_LOG || flag) console.log(`[MyRadonInterface] ${msg}`);
  },
};

export default MyRadonInterface;
