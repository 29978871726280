import { Users } from "react-feather";
import RedirectToRoot from "../views/auth/RedirectToRoot";
import SignIn from "../views/auth/SignIn";
import SignUp from "../views/auth/SignUp";
import IntegrateWithApp from "../views/auth/IntegrateWithApp";
import VerifyPasscode from "../views/auth/VerifyPasscode";
import ForgotPassword from "../views/auth/ForgotPassword";
import ResetPassword from "../views/auth/ResetPassword";
import ResetPwdConfirm from "../views/auth/ResetPwdConfirm";
import PasscodeRequired from "../views/auth/PasscodeRequired";
import Page404 from "../views/auth/Page404";
import Page500 from "../views/auth/Page500";
import Setting from "../views/account/Setting";
import React from "react";
import async from "../components/Async";
import projectIcon from "../assets/img/project-icon-24.png";
import reportIcon from "../assets/img/myreport.png";
import supportIcon from "../assets/img/ic_info_24.png";
import settingsIcon from "../assets/img/settings-icon-24.png";
import singleUnitIcon from "../assets/img/Home_24_white.png";
import SupportPage from "../views/dashboards/Support";
import Report from "../views/report/Report";
import ReportList from "../views/report/ReportList";

const ProjectListView = async(() => import("../views/dashboards/Projects"));
const SingleTestsListView = async(() =>
  import("../views/dashboards/SingleTests")
);
const ProjectDetailsView = async(() =>
  import("../views/dashboards/Projects/projectDetails")
);

export const projectRoutes = {
  id: "Project",
  path: "/",
  icon: <img src={projectIcon} alt="projectIcon" />,
  containsHome: true,
  children: [
    {
      path: "/",
      component: RedirectToRoot,
    },
    {
      path: "/projects",
      component: ProjectListView,
    },
    {
      path: "/projects/details",
      name: "Project Details",
      component: ProjectDetailsView,
    },
  ],
  component: null,
};

export const projectRoutesForSideBar = {
  id: "Project",
  path: "/projects",
  icon: <img src={projectIcon} alt="projectIcon" />,
  children: null,
  component: ProjectListView,
};

export const singleTestRoutes = {
  id: "Single Tests",
  path: "/single",
  icon: <img src={singleUnitIcon} alt="singleUnitIcon" />,
  containsHome: true,
  children: [
    {
      path: "/",
      component: RedirectToRoot,
    },
    {
      path: "/single",
      component: SingleTestsListView,
    },
  ],
  component: null,
};

export const singleTestRoutesForSideBar = {
  id: "Single Tests",
  path: "/single?tab=tests",
  icon: <img src={singleUnitIcon} alt="singleUnitIcon" />,
  children: null,
  component: SingleTestsListView,
};

export const supportPageRoutes = {
  id: "Support",
  path: "/",
  icon: <img src={supportIcon} alt="supportIcon" />,
  containsHome: true,
  children: [
    {
      path: "/",
      component: RedirectToRoot,
    },
    {
      path: "/support",
      component: SupportPage,
    },
  ],
  component: null,
};

export const supportPageRoutesForSideBar = {
  id: "Support",
  path: "/support",
  icon: <img src={supportIcon} alt="supportIcon" />,
  children: null,
  component: SupportPage,
};

export const authRoutesForSideBar = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/integrate-app",
      name: "Integrate With App",
      component: IntegrateWithApp,
    },
  ],
  component: null,
};

export const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/verify-code",
      name: "Verify Code",
      component: VerifyPasscode,
    },
    {
      path: "/auth/forgot-password",
      name: "Forgot Password",
      component: ForgotPassword,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/reset-pwd-confirm",
      name: "Reset Password Confirm",
      component: ResetPwdConfirm,
    },
    {
      path: "/auth/passcode-required",
      name: "Passcode Required",
      component: PasscodeRequired,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
    {
      path: "/auth/integrate-app",
      name: "Integrate With App",
      component: IntegrateWithApp,
    },
  ],
  component: null,
};

export const settingsRoutes = {
  id: "Settings",
  path: "/settings",
  icon: <img src={settingsIcon} alt="settingsIcon" />,
  children: [
    {
      path: "/settings/account",
      name: "Settings",
      component: Setting,
    },
  ],
  component: null,
};

export const settingsForSideBar = {
  id: "Settings",
  path: "/settings/account",
  icon: <img src={settingsIcon} alt="settingsIcon" />,
  component: Setting,
  children: null,
};

export const reportRoutes = {
  id: "My Radon",
  path: "/report",
  icon: <img src={reportIcon} alt="reportIcon" />,
  children: [
    {
      path: "/report",
      name: "My Radon",
      component: Report,
    },
  ],
  component: null,
};

export const reportForSideBar = {
  id: "My Radon",
  path: "/report",
  icon: <img src={reportIcon} alt="reportIcon" />,
  component: Report,
  children: null,
};

export const reportListRoutes = {
  id: "My Report",
  path: "/reports",
  icon: <img src={reportIcon} alt="reportIcon" />,
  children: [
    {
      path: "/reports",
      name: "My Report",
      component: ReportList,
    },
  ],
  component: null,
};

export const reportListForSideBar = {
  id: "My Report",
  path: "/reports",
  icon: <img src={reportIcon} alt="reportIcon" style={{ width: 18, height: 18 }} />,
  component: ReportList,
  children: null,
};
