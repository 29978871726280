import React from "react";
import "../../assets/css/common.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Space from "../../components/Space";

function Loading(props) {
  return (
    <>
      {/*<Typography mb={'228px'}/>*/}
      <Space height={props.top ? props.top : "228px"}></Space>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent="center"
      >
        <div className="progress">
          <CircularProgress />
        </div>
        <div className="s20"></div>
        {/*<Typography variant={'body1'}>Loading ..</Typography>*/}
      </Box>
      <Space height={props.bottom ? props.bottom : "0px"}></Space>
    </>
  );
}

export default Loading;
