export const NO_RESULTS = 100;
export const SUCCESS = 200;
export const UNAVAILABLE_INPUT = 300;
export const NOT_VALID = 301;
export const FAILURE = 400;
export const MISSING_PARAMETER_IN_BODY = 401;
export const MISSING_PARAMETER_IN_QS = 402;
export const MISSING_PARAMETER_IN_HEADER = 403;
export const BAD_PARAMETER = 404;
export const ALREADY_EXIST = 409; // when already an element exists
export const NO_ELEMENTS_MATCHED = 410; // when there are no return values according to input data
export const INSUFFICIENT_PARAMETERS = 411; // when # of input parameters is insufficient
export const NOT_AUTHORIZED = 499; // when a token is not valid
export const INTERNAL_SERVER_ERROR = 500; // if not defined somewhere else, server will just return this
export const DB_ERROR = 501;
