import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Brush,
  ResponsiveContainer,
} from "recharts";
import {
  Card,
  CardContent,
  Paper,
  Tabs,
  Tab,
  Box,
  makeStyles,
  withStyles
} from "@material-ui/core";
import Space from "../../components/Space";
import { weather_icon } from "../../constants/values";

const SectionTitle = styled.h2`
  font-weight: bold;
  margin-left: 16px;
  margin-bottom: 8px;
  font-size: 18px;
  color: #333;
`;

const MyRadonLineChart = (param) => {
  const [reportData, setReportData] = useState(param.data);
  const [selectedTab, setSelectedTab] = useState(0);
  const [fixedBrushStart, setFixedBrushStart] = useState(0);  // 고정 시작 인덱스
  const [fixedBrushEnd, setFixedBrushEnd] = useState(12); // 고정 끝 인덱스
  const [visibleRange, setVisibleRange] = useState([fixedBrushStart, fixedBrushEnd]); // 선택된 범위 상태
  const [interval, setInterval] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const classes = useStyles({ screenWidth });
  const getLineColors = () => {
    switch (selectedTab) {
      case 0:
        return {
          radonColor: "#3DB5E6",
          secondLineColor: "#FF9D66",
          tooltipTextColor: "#A9A9A9",
          labelColor: "#FFBC8C",
        };
      case 1:
        return {
          radonColor: "#3DB5E6",
          secondLineColor: "#575195",
          tooltipTextColor: "#A9A9A9",
          labelColor: "#575195",
        };
      case 2:
        return {
          radonColor: "#3DB5E6",
          secondLineColor: "#6BC04B",
          tooltipTextColor: "#A9A9A9",
          labelColor: "#6BC04B",
        };
      case 3:
        return {
          radonColor: "#3DB5E6",
          secondLineColor: "#8E59EF",
          tooltipTextColor: "#A9A9A9",
          labelColor: "#8E59EF",
        };
      default:
        return {
          radonColor: "#3DB5E6",
          secondLineColor: "#FF9D66",
          tooltipTextColor: "#A9A9A9",
          labelColor: "#FFBC8C",
        };
    }
  };

  const [tooltipTextColor, setTooltipTextColor] = useState("#A9A9A9");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setScreenWidth(width);
      if (width < 600) {
        setInterval(1);
      } else if (width < 960) {
        setInterval(0);
      } else {
        setInterval(0);
      }
    };
    setTooltipTextColor(getLineColors().tooltipTextColor);
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const CustomTabs = withStyles({
    root: {
      minHeight: "36px",
    },
    indicator: {
      display: "none",
    },
  })(Tabs);

  const CustomTab = withStyles((theme) => ({
    root: {
      padding: "4px 10px", // 기본 크기
      margin: "0 6px",
      borderRadius: "20px",
      border: "1px solid #565191",
      color: "#565191",
      fontSize: "12px",
      textTransform: "none",
      minHeight: "30px",
      "&.Mui-selected": {
        color: "#FFFFFF",
        backgroundColor: getLineColors().secondLineColor,
        border: "none",
      },
      // ✅ 750px 이하에서 더 작은 크기로 조정
      [theme.breakpoints.down(750)]: {
        padding: "2px 6px", // 패딩 축소
        margin: "0 5px", // 간격 줄이기
        fontSize: "12px", // 글자 크기 줄이기
        minWidth: "100px", // 버튼 최소 너비 지정
      },
      [theme.breakpoints.down(500)]: {
        padding: "1px 15px", // 더 작아질 경우 추가 조정
        margin: "0 5px",
        fontSize: "12px",
        minWidth: "50px",
      },
    },
  }))((props) => {
    const { selectedTab, ...otherProps } = props;
    return <Tab {...otherProps} />;
  });


  const renderWeatherIcon = (weather) => {
    const weatherData = weather_icon.find(w => w.day === weather || w.night === weather);
    return weatherData ? `https://cdn.weatherapi.com/weather/64x64/day/${weatherData.icon}.png` : null;
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleBrushChange = (e) => {
    // 브러쉬 범위는 고정하지만, visibleRange는 계속 업데이트
    setVisibleRange([e.startIndex, e.endIndex]);
  };



  const renderChart = () => {
    const { radonColor, secondLineColor } = getLineColors();

    return (
      <div className={classes.chartContainer}>
        <ResponsiveContainer width="100%" className={classes.centerContainer}>
          <LineChart data={reportData} margin={{ top: 20, right: 0, left: 0, bottom: 80 }}>
            <CartesianGrid stroke="#D3D3D3" strokeDasharray="1 3" />
            <XAxis
              interval={interval}
              dataKey="name"
              label={{ value: "", position: "insideBottom", offset: -10 }}
              tick={({ x, y, payload }) => {
                const item = reportData[payload.index];
                const startIndex = visibleRange[0];
                const endIndex = visibleRange[1];
                const weather = reportData[startIndex + payload.index]?.weather;
                const time = reportData[startIndex + payload.index]?.time;
                const period = reportData[startIndex + payload.index]?.period;
                const iconUrl = renderWeatherIcon(weather);

                return (
                  <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={12} textAnchor="middle" fill="#666" fontSize={10}>
                      {time}
                    </text>
                    <text x={0} y={0} dy={21} textAnchor="middle" fill="#666" fontSize={8}>
                      {period}
                    </text>
                    {iconUrl && (
                      <foreignObject x="-12" y="-284" width="24" height="24">
                        <img src={iconUrl} width="24" height="24" alt="weather icon" />
                      </foreignObject>
                    )}
                  </g>
                );
              }}
              tickLine={{ stroke: "#D3D3D3" }}
            />

            <YAxis
              yAxisId="left"
              tick={{ fontSize: 10, fill: "#666" }}
              label={null}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              tick={{ fontSize: 10, fill: "#666" }}
              label={null}
            />
            <Tooltip
              formatter={(value, name) => {
                if (name === "value1") {
                  return [value, "Radon (pCi/L)"];
                } else if (name === "value2") {
                  return [value, "Temperature (°F)"];
                } else if (name === "value3") {
                  return [value, "Humidity (%)"];
                } else if (name === "value4") {
                  return [value, "Wind (mph)"];
                } else if (name === "value5") {
                  return [value, "Pressure (inHg)"];
                }
                return value;
              }}
              contentStyle={{
                backgroundColor: "#f9f9f9",
                color: tooltipTextColor,
                border: `1px solid ${tooltipTextColor}`  // tooltipTextColor를 테두리 색상으로 적용
              }}
            />
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="value1"
              stroke={radonColor}
              dot={false}
            />
            <Line
              yAxisId="right"
              type="monotone"
              dataKey={
                selectedTab === 0
                  ? "value2"
                  : selectedTab === 1
                  ? "value3"
                  : selectedTab === 2
                    ? "value4"
                    : "value5"
              }
              stroke={secondLineColor}
              dot={false}
              strokeDasharray="5 5"
            />
            <Brush
              dataKey="name"
              startIndex={fixedBrushStart}
              endIndex={fixedBrushEnd}
              onChange={handleBrushChange} // 브러쉬 이동을 허용하여 날씨 아이콘이 변경되도록 함
              height={70}
              y={320}
              fill="#FFF"
              stroke="#ABABAB"
              travellerWidth={1}
              travellerFill="#D3D3D3"
            >
              <LineChart>
                <CartesianGrid stroke="#D3D3D3" strokeDasharray="1 3" />
                <Line type="monotone" dataKey="value1" stroke={getLineColors().radonColor} dot={false} />
              </LineChart>
            </Brush>

          </LineChart>

        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <Card className={classes.root} elevation={0}>
      <CardContent className={classes.cardContent}>
        <CustomTabs value={selectedTab} onChange={handleTabChange} centered style={{ marginTop: 16 }}>
          <CustomTab label={screenWidth <= 600 ? "Temp" : "Temperature"} selectedTab={selectedTab === 0} />
          <CustomTab label={screenWidth <= 600 ? "Humid" : "Humidity"} selectedTab={selectedTab === 1} />
          <CustomTab label={screenWidth <= 600 ? "Wind" : "Wind"} selectedTab={selectedTab === 2} />
          <CustomTab label={screenWidth <= 600 ? "Press" : "Pressure"} selectedTab={selectedTab === 3} />
        </CustomTabs>
        <Box className={classes.tabContent}>
          <div className={classes.brushContainer}>
            <Space height={"20px"} />
            <div className={classes.axisLabel}>
              <span style={{ color: getLineColors().radonColor, marginLeft: "20px", fontSize: 9 }}>
                Radon (pCi/L)
              </span>
              <span style={{ color: getLineColors().labelColor, marginRight: "20px", fontSize: 9 }}>
              {selectedTab === 0
                ? "Temperature (°F)"
                : selectedTab === 1
                  ? "Humidity (%)"
                  : selectedTab === 2
                    ? "Wind (mph)"
                    : "Pressure (inHg)"}
              </span>
            </div>
            {renderChart()}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
              <div style={{ display: "flex", alignItems: "center", marginRight: "20px" }}>
                <span style={{ display: "inline-block", width: "30px", height: "4px", backgroundColor: "#3DB5E6", marginRight: "10px" }}></span>
                <span style={{ fontSize: "12px", color: "#333" }}>Radon (pCi/L)</span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ display: "inline-block", width: "30px", height: "4px", borderTop: "4px dashed", borderColor: getLineColors().labelColor, marginRight: "10px" }}></span>
                <span style={{ fontSize: "12px", color: "#333" }}>
                {selectedTab === 0
                  ? "Temperature (°F)"
                  : selectedTab === 1
                    ? "Humidity (%)"
                    : selectedTab === 2
                      ? "Wind (mph)"
                      : "Pressure (inHg)"}
                </span>
              </div>
            </div>
          </div>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MyRadonLineChart;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    margin: 0,
    padding: 0,
  },
  tabContent: {
    backgroundColor: "white",
    minHeight: "450px",
  },
  centerContainer: {
    // backgroundColor: "red",
    justifyContent: "center",  /* 가로 중앙 정렬 */
    alignItems: "center",  /* 세로 중앙 정렬 */
  },
  chartContainer: {
    backgroundColor: "white",
    width: "100%",
    // maxWidth: "100%",  /* 부모보다 커지지 않도록 제한 */
    height: "450px",
    position: "relative",
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",  /* 가로 중앙 정렬 */
    alignItems: "center",  /* 세로 중앙 정렬 */
  },
  // chartContainer: {
  //   backgroundColor: "white",
  //   width: "100%",
  //   height: "450px",
  //   position: "relative",
  //   marginTop: "20px",
  //   display: "flex",
  //   justifyContent: "center"
  // },
  brushContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  axisLabel: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 14,
  },
  cardContent: {
    padding: 0,
    margin: 0,
  },
  "@media (max-width: 400px)": {
    root: {
      fontSize: "10px",
    },
    axisLabel: {
      fontSize: "10px",
    },
    chartContainer: {
      width: "115%",
      marginLeft: "-7%",  // 왼쪽으로 이동하여 균형 조정
      // transform: "translateX(-2.5%)", // 미세한 중앙 정렬 조정
      paddingLeft: 0,
      paddingRight: 0,
      display: "flex",
      justifyContent: "center",  /* 가로 중앙 정렬 */
      alignItems: "center",  /* 세로 중앙 정렬 */
    },
    cardContent: {
      marginTop: "8px",
    },
  },
  "@media (min-width: 400px) and (max-width: 600px)": {
    chartContainer: {
      width: "110%",
      marginLeft: "-5%",  // 왼쪽으로 이동하여 균형 조정
      // transform: "translateX(-2.5%)", // 미세한 중앙 정렬 조정
      paddingLeft: 0,
      paddingRight: 0,
      display: "flex",
      justifyContent: "center",  /* 가로 중앙 정렬 */
      alignItems: "center",  /* 세로 중앙 정렬 */
    }
  },
}));
