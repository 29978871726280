// RADON LEVEL RELATED
export const EPA_CONSIDER_FIXING_HOME = 2.7;
export const EPA_RECOMMEND_FIXING_HOME = 4.0;
export const EPA_CONSIDER_FIXING_HOME_BQ = 100;
export const EPA_RECOMMEND_FIXING_HOME_BQ = 150;
export const UNIT_CONVERSION_SCALE = 37;
export const NORMAL_STATUS = "normal";
export const CONSIDER_FIXING_STATUS = "consider_fixing";
export const RECOMMEND_FIXING_STATUS = "recommend_fixing";

// NETWORK RELATED
export const CRITICAL_ACTION_TIMEOUT = 30000;

// EVENT RELATED
export const ACTIVE = "ACTIVATE";
export const INACTIVE = "DEACTIVATE";
export const OVER4 = "Over 4pCi/L";
export const BELOW4 = "Below 4pCi/L";
export const ON = "on";
export const OFF = "off";
export const PCI = "pCi/L";
export const BQ = "Bq/m3";
export const UNIT_EVENT = "UNIT";
export const THRESHOLD_EVENT = "THRESHOLD";
export const ALARM_EVENT = "ALARM";
export const enableAlert = false;

// Monitoring mode
export const _24H_AVG = 24;
export const REALTIME = 1;
export const THRESHOLD_TO_SHOW = 24 * 60; // 24 hours in minutes

// Cognito attributes
export const CUSTOM_COMPANY_EMAIL = "custom:companyEmail";

// QR code scan
// issue report
export const ISS_WIFI = "Wi-Fi network connection failure";
export const ISS_OTHERS = "Other";
export const DISCLAIMER = "This test result is provided for informational purposes only and should not be considered a certified or professional assessment. We do not assume any legal responsibility for decisions or actions taken based on these results. For accurate radon testing and mitigation, consult a certified radon professional."
export const weather_icon = [
  {
    "code" : 1000,
    "day" : "Sunny",
    "night" : "Clear",
    "icon" : 113
  },
  {
    "code" : 1003,
    "day" : "Partly cloudy",
    "night" : "Partly cloudy",
    "icon" : 116
  },
  {
    "code" : 1006,
    "day" : "Cloudy",
    "night" : "Cloudy",
    "icon" : 119
  },
  {
    "code" : 1009,
    "day" : "Overcast",
    "night" : "Overcast",
    "icon" : 122
  },
  {
    "code" : 1030,
    "day" : "Mist",
    "night" : "Mist",
    "icon" : 143
  },
  {
    "code" : 1063,
    "day" : "Patchy rain possible",
    "night" : "Patchy rain possible",
    "icon" : 176
  },
  {
    "code" : 1066,
    "day" : "Patchy snow possible",
    "night" : "Patchy snow possible",
    "icon" : 179
  },
  {
    "code" : 1069,
    "day" : "Patchy sleet possible",
    "night" : "Patchy sleet possible",
    "icon" : 182
  },
  {
    "code" : 1072,
    "day" : "Patchy freezing drizzle possible",
    "night" : "Patchy freezing drizzle possible",
    "icon" : 185
  },
  {
    "code" : 1087,
    "day" : "Thundery outbreaks possible",
    "night" : "Thundery outbreaks possible",
    "icon" : 200
  },
  {
    "code" : 1114,
    "day" : "Blowing snow",
    "night" : "Blowing snow",
    "icon" : 227
  },
  {
    "code" : 1117,
    "day" : "Blizzard",
    "night" : "Blizzard",
    "icon" : 230
  },
  {
    "code" : 1135,
    "day" : "Fog",
    "night" : "Fog",
    "icon" : 248
  },
  {
    "code" : 1147,
    "day" : "Freezing fog",
    "night" : "Freezing fog",
    "icon" : 260
  },
  {
    "code" : 1150,
    "day" : "Patchy light drizzle",
    "night" : "Patchy light drizzle",
    "icon" : 263
  },
  {
    "code" : 1153,
    "day" : "Light drizzle",
    "night" : "Light drizzle",
    "icon" : 266
  },
  {
    "code" : 1168,
    "day" : "Freezing drizzle",
    "night" : "Freezing drizzle",
    "icon" : 281
  },
  {
    "code" : 1171,
    "day" : "Heavy freezing drizzle",
    "night" : "Heavy freezing drizzle",
    "icon" : 284
  },
  {
    "code" : 1180,
    "day" : "Patchy light rain",
    "night" : "Patchy light rain",
    "icon" : 293
  },
  {
    "code" : 1183,
    "day" : "Light rain",
    "night" : "Light rain",
    "icon" : 296
  },
  {
    "code" : 1186,
    "day" : "Moderate rain at times",
    "night" : "Moderate rain at times",
    "icon" : 299
  },
  {
    "code" : 1189,
    "day" : "Moderate rain",
    "night" : "Moderate rain",
    "icon" : 302
  },
  {
    "code" : 1192,
    "day" : "Heavy rain at times",
    "night" : "Heavy rain at times",
    "icon" : 305
  },
  {
    "code" : 1195,
    "day" : "Heavy rain",
    "night" : "Heavy rain",
    "icon" : 308
  },
  {
    "code" : 1198,
    "day" : "Light freezing rain",
    "night" : "Light freezing rain",
    "icon" : 311
  },
  {
    "code" : 1201,
    "day" : "Moderate or heavy freezing rain",
    "night" : "Moderate or heavy freezing rain",
    "icon" : 314
  },
  {
    "code" : 1204,
    "day" : "Light sleet",
    "night" : "Light sleet",
    "icon" : 317
  },
  {
    "code" : 1207,
    "day" : "Moderate or heavy sleet",
    "night" : "Moderate or heavy sleet",
    "icon" : 320
  },
  {
    "code" : 1210,
    "day" : "Patchy light snow",
    "night" : "Patchy light snow",
    "icon" : 323
  },
  {
    "code" : 1213,
    "day" : "Light snow",
    "night" : "Light snow",
    "icon" : 326
  },
  {
    "code" : 1216,
    "day" : "Patchy moderate snow",
    "night" : "Patchy moderate snow",
    "icon" : 329
  },
  {
    "code" : 1219,
    "day" : "Moderate snow",
    "night" : "Moderate snow",
    "icon" : 332
  },
  {
    "code" : 1222,
    "day" : "Patchy heavy snow",
    "night" : "Patchy heavy snow",
    "icon" : 335
  },
  {
    "code" : 1225,
    "day" : "Heavy snow",
    "night" : "Heavy snow",
    "icon" : 338
  },
  {
    "code" : 1237,
    "day" : "Ice pellets",
    "night" : "Ice pellets",
    "icon" : 350
  },
  {
    "code" : 1240,
    "day" : "Light rain shower",
    "night" : "Light rain shower",
    "icon" : 353
  },
  {
    "code" : 1243,
    "day" : "Moderate or heavy rain shower",
    "night" : "Moderate or heavy rain shower",
    "icon" : 356
  },
  {
    "code" : 1246,
    "day" : "Torrential rain shower",
    "night" : "Torrential rain shower",
    "icon" : 359
  },
  {
    "code" : 1249,
    "day" : "Light sleet showers",
    "night" : "Light sleet showers",
    "icon" : 362
  },
  {
    "code" : 1252,
    "day" : "Moderate or heavy sleet showers",
    "night" : "Moderate or heavy sleet showers",
    "icon" : 365
  },
  {
    "code" : 1255,
    "day" : "Light snow showers",
    "night" : "Light snow showers",
    "icon" : 368
  },
  {
    "code" : 1258,
    "day" : "Moderate or heavy snow showers",
    "night" : "Moderate or heavy snow showers",
    "icon" : 371
  },
  {
    "code" : 1261,
    "day" : "Light showers of ice pellets",
    "night" : "Light showers of ice pellets",
    "icon" : 374
  },
  {
    "code" : 1264,
    "day" : "Moderate or heavy showers of ice pellets",
    "night" : "Moderate or heavy showers of ice pellets",
    "icon" : 377
  },
  {
    "code" : 1273,
    "day" : "Patchy light rain with thunder",
    "night" : "Patchy light rain with thunder",
    "icon" : 386
  },
  {
    "code" : 1276,
    "day" : "Moderate or heavy rain with thunder",
    "night" : "Moderate or heavy rain with thunder",
    "icon" : 389
  },
  {
    "code" : 1279,
    "day" : "Patchy light snow with thunder",
    "night" : "Patchy light snow with thunder",
    "icon" : 392
  },
  {
    "code" : 1282,
    "day" : "Moderate or heavy snow with thunder",
    "night" : "Moderate or heavy snow with thunder",
    "icon" : 395
  }
];

export const sample_report_data = [
  {
    "name": "03/03/2025 10:03 PM",
    "time": "10:03",
    "period": "PM",
    "value1": 0.22,
    "value2": 51.1,
    "value3": 77,
    "value4": 5.4,
    "value5": 30.19,
    "weather": "Partly cloudy"
  },
  {
    "name": "03/03/2025 09:03 PM",
    "time": "09:03",
    "period": "PM",
    "value1": 0.19,
    "value2": 52,
    "value3": 77,
    "value4": 7.4,
    "value5": 30.18,
    "weather": "Partly cloudy"
  },
  {
    "name": "03/03/2025 08:03 PM",
    "time": "08:03",
    "period": "PM",
    "value1": 0.19,
    "value2": 55,
    "value3": 69,
    "value4": 11,
    "value5": 30.17,
    "weather": "Partly cloudy"
  },
  {
    "name": "03/03/2025 07:03 PM",
    "time": "07:03",
    "period": "PM",
    "value1": 0.43,
    "value2": 55.9,
    "value3": 62,
    "value4": 9.6,
    "value5": 30.17,
    "weather": "Partly cloudy"
  },
  {
    "name": "03/03/2025 06:03 PM",
    "time": "06:03",
    "period": "PM",
    "value1": 0.3,
    "value2": 57.9,
    "value3": 57,
    "value4": 8.1,
    "value5": 30.17,
    "weather": "Partly cloudy"
  },
  {
    "name": "03/03/2025 05:03 PM",
    "time": "05:03",
    "period": "PM",
    "value1": 0.35,
    "value2": 57,
    "value3": 55,
    "value4": 8.1,
    "value5": 30.17,
    "weather": "Partly cloudy"
  },
  {
    "name": "03/03/2025 04:03 PM",
    "time": "04:03",
    "period": "PM",
    "value1": 0.22,
    "value2": 57.9,
    "value3": 53,
    "value4": 7.6,
    "value5": 30.17,
    "weather": "Sunny"
  },
  {
    "name": "03/03/2025 03:03 PM",
    "time": "03:03",
    "period": "PM",
    "value1": 0.35,
    "value2": 57,
    "value3": 57,
    "value4": 5.8,
    "value5": 30.18,
    "weather": "Sunny"
  },
  {
    "name": "03/03/2025 02:03 PM",
    "time": "02:03",
    "period": "PM",
    "value1": 0.38,
    "value2": 54,
    "value3": 59,
    "value4": 3.8,
    "value5": 30.18,
    "weather": "Sunny"
  },
  {
    "name": "03/03/2025 12:20 PM",
    "time": "12:20",
    "period": "PM",
    "value1": 0.73,
    "value2": 52,
    "value3": 59,
    "value4": 2.7,
    "value5": 30.19,
    "weather": "Sunny"
  },
  {
    "name": "03/03/2025 11:20 AM",
    "time": "11:20",
    "period": "AM",
    "value1": 0.7,
    "value2": 48.9,
    "value3": 71,
    "value4": 2.7,
    "value5": 30.18,
    "weather": "Sunny"
  },
  {
    "name": "03/03/2025 10:20 AM",
    "time": "10:20",
    "period": "AM",
    "value1": 0.62,
    "value2": 44.1,
    "value3": 82,
    "value4": 2.2,
    "value5": 30.17,
    "weather": "Sunny"
  },
  {
    "name": "03/03/2025 09:20 AM",
    "time": "09:20",
    "period": "AM",
    "value1": 0.22,
    "value2": 39.9,
    "value3": 86,
    "value4": 2.2,
    "value5": 30.15,
    "weather": "Sunny"
  },
  {
    "name": "03/03/2025 08:20 AM",
    "time": "08:20",
    "period": "AM",
    "value1": 0.35,
    "value2": 41.5,
    "value3": 86,
    "value4": 2.2,
    "value5": 30.11,
    "weather": "Clear"
  },
  {
    "name": "03/03/2025 07:20 AM",
    "time": "07:20",
    "period": "AM",
    "value1": 0.57,
    "value2": 42.1,
    "value3": 85,
    "value4": 2.5,
    "value5": 30.12,
    "weather": "Clear"
  },
  {
    "name": "03/03/2025 06:20 AM",
    "time": "06:20",
    "period": "AM",
    "value1": 0.51,
    "value2": 42.1,
    "value3": 85,
    "value4": 2.5,
    "value5": 30.11,
    "weather": "Clear"
  },
  {
    "name": "03/03/2025 05:20 AM",
    "time": "05:20",
    "period": "AM",
    "value1": 0.27,
    "value2": 42.1,
    "value3": 85,
    "value4": 3.1,
    "value5": 30.1,
    "weather": "Partly cloudy"
  },
  {
    "name": "03/03/2025 04:20 AM",
    "time": "04:20",
    "period": "AM",
    "value1": 0.35,
    "value2": 45,
    "value3": 80,
    "value4": 4.7,
    "value5": 30.1,
    "weather": "Partly cloudy"
  },
  {
    "name": "03/03/2025 03:20 AM",
    "time": "03:20",
    "period": "AM",
    "value1": 0.19,
    "value2": 46,
    "value3": 76,
    "value4": 6.5,
    "value5": 30.09,
    "weather": "Partly cloudy"
  },
  {
    "name": "03/03/2025 02:20 AM",
    "time": "02:20",
    "period": "AM",
    "value1": 0.35,
    "value2": 48,
    "value3": 73,
    "value4": 6.5,
    "value5": 30.08,
    "weather": "Overcast"
  },
  {
    "name": "03/03/2025 01:20 AM",
    "time": "01:20",
    "period": "AM",
    "value1": 0.22,
    "value2": 48,
    "value3": 73,
    "value4": 8.7,
    "value5": 30.08,
    "weather": "Partly cloudy"
  },
  {
    "name": "03/03/2025 12:20 AM",
    "time": "12:20",
    "period": "AM",
    "value1": 0.19,
    "value2": 48,
    "value3": 73,
    "value4": 9.6,
    "value5": 30.09,
    "weather": "Partly cloudy"
  },
  {
    "name": "03/02/2025 11:20 PM",
    "time": "11:20",
    "period": "PM",
    "value1": 0.19,
    "value2": 48,
    "value3": 73,
    "value4": 9.6,
    "value5": 30.09,
    "weather": "Clear"
  },
  {
    "name": "03/02/2025 10:20 PM",
    "time": "10:20",
    "period": "PM",
    "value1": 0.19,
    "value2": 48.9,
    "value3": 69,
    "value4": 9.6,
    "value5": 30.08,
    "weather": "Clear"
  },
  {
    "name": "03/02/2025 09:20 PM",
    "time": "09:20",
    "period": "PM",
    "value1": 0.19,
    "value2": 48.9,
    "value3": 71,
    "value4": 12.3,
    "value5": 30.08,
    "weather": "Clear"
  },
  {
    "name": "03/02/2025 08:20 PM",
    "time": "08:20",
    "period": "PM",
    "value1": 0.19,
    "value2": 51.1,
    "value3": 68,
    "value4": 14.3,
    "value5": 30.07,
    "weather": "Partly cloudy"
  },
  {
    "name": "03/02/2025 07:20 PM",
    "time": "07:20",
    "period": "PM",
    "value1": 0.19,
    "value2": 53.1,
    "value3": 66,
    "value4": 15.4,
    "value5": 30.06,
    "weather": "Partly cloudy"
  },
  {
    "name": "03/02/2025 06:20 PM",
    "time": "06:20",
    "period": "PM",
    "value1": 0.38,
    "value2": 55,
    "value3": 62,
    "value4": 16.8,
    "value5": 30.05,
    "weather": "Partly cloudy"
  },
  {
    "name": "03/02/2025 05:20 PM",
    "time": "05:20",
    "period": "PM",
    "value1": 0.51,
    "value2": 55.4,
    "value3": 59,
    "value4": 17.6,
    "value5": 30.04,
    "weather": "Partly cloudy"
  }
];

export const TIMEZONE_MAP = {
  AL: "America/Chicago",
  AK: "America/Anchorage",
  AZ: "America/Phoenix",
  AR: "America/Chicago",
  CA: "America/Los_Angeles",
  CO: "America/Denver",
  CT: "America/New_York",
  DE: "America/New_York",
  FL: "America/New_York",
  GA: "America/New_York",
  HI: "Pacific/Honolulu",
  ID: "America/Boise",
  IL: "America/Chicago",
  IN: "America/Indiana/Indianapolis",
  IA: "America/Chicago",
  KS: "America/Chicago",
  KY: "America/New_York",
  LA: "America/Chicago",
  ME: "America/New_York",
  MD: "America/New_York",
  MA: "America/New_York",
  MI: "America/Detroit",
  MN: "America/Chicago",
  MS: "America/Chicago",
  MO: "America/Chicago",
  MT: "America/Denver",
  NE: "America/Chicago",
  NV: "America/Los_Angeles",
  NH: "America/New_York",
  NJ: "America/New_York",
  NM: "America/Denver",
  NY: "America/New_York",
  NC: "America/New_York",
  ND: "America/Chicago",
  OH: "America/New_York",
  OK: "America/Chicago",
  OR: "America/Los_Angeles",
  PA: "America/New_York",
  RI: "America/New_York",
  SC: "America/New_York",
  SD: "America/Chicago",
  TN: "America/Chicago",
  TX: "America/Chicago",
  UT: "America/Denver",
  VT: "America/New_York",
  VA: "America/New_York",
  WA: "America/Los_Angeles",
  WV: "America/New_York",
  WI: "America/Chicago",
  WY: "America/Denver",
  DC: "America/New_York",
  PR: "America/Puerto_Rico",
  GU: "Pacific/Guam",
  AS: "Pacific/Pago_Pago",
  VI: "America/St_Thomas",
  MP: "Pacific/Saipan",
};

// states in the US
export const states = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  IA: "IA",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  MA: "MA",
  MD: "MD",
  ME: "ME",
  MI: "MI",
  MN: "MN",
  MO: "MO",
  MS: "MS",
  MT: "MT",
  NC: "NC",
  ND: "ND",
  NE: "NE",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NV: "NV",
  NY: "NY",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VA: "VA",
  VT: "VT",
  WA: "WA",
  WI: "WI",
  WV: "WV",
  WY: "WY",
};

export const buildingType = {
  SF: "Single-family home",
  C1: "Commercial",
  E1: "Educational",
  H1: "Healthcare",
  G1: "Government",
  MF: "Multi-family home",
  P1: "Public Buildings",
};

// export const singleBuildingType = {
//   OF: "Office",
//   SC: "School",
//   HS: "House",
//   OT: "Other",
// };

export const singleBuildingType = {
  SF: "Single-family home",
  MA: "MF apartment",
  C1: "Commercial",
  SC: "School",
  DC: "Daycare",
  H1: "Healthcare",
  G1: "Government",
  P1: "Public Buildings",
  OT: "Other",
  OF: "Office",
  HS: "House"
};

export const structureType = {
  BS: "Basement",
  CR: "Crawlspace",
  SL: "Slab-on-Grade",
  MS: "Combination",
  OT: "Other",
};

export const testPurpose = {
  RET: "Real estate transaction",
  NC: "New construction",
  OT: "Other",
  PRM: "Pre-mitigation",
  PM: "Post-mitigation",
  undefined: "N/A",
};

export const roomType = {
  LV: "Living room",
  DN: "Dining room",
  BD: "Bedroom",
  PL: "Playroom",
  BS: "Basement",
  OF: "Office",
  CS: "Classroom",
  OT: "Other",
};

export const closedBldgProtocol = {
  0: "No",
  1: "Yes",
  undefined: "N/A",
};

export const testFloor = {
  BS: "Basement",
  FL1: "1st Floor",
  FL2: "2nd Floor",
  "FL3+": "3rd Floor or above",
  undefined: "N/A",
};

export const mitigationSystem = {
  0: "Not Installed",
  1: "Installed",
  undefined: "N/A",
};

export const measurementDuration = {
  "48H": "48 hours",
  "72H": "72 hours",
  "96H": "96 hours",
};

export const delayTime = {
  "0H": "0 hours",
  "4H": "4 hours",
  "12H": "12 hours",
  "24H": "24 hours",
};

export const measurementUnit = {
  0: "pCi/L",
  1: "Bq/m\u00B3",
};

export const measurementStatus = {
  0: "Ready",
  1: "In progress",
  2: "Finished",
  3: "Downloaded",
  4: "Uploaded",
  5: "Self-Finished",
};

export const statusColor = {
  0: "#DE7548",
  1: "#6BC04B",
  2: "#3DB5E6",
  3: "#4C46C1",
  4: "#4C46C1",
};

export const reportResult = {
  0: "≥ EPA",
  1: "< EPA",
};

export const reportResultColor = {
  0: "#DE7548",
  1: "#4CAF50",
};

export const duplicateTest = {
  0: "Standard",
  1: "Duplicate test (Original)",
  2: "Duplicate test (Duplicate)",
};

export const duplicateTestShorthand = {
  0: "N",
  1: "Y(O)",
  2: "Y(D)",
};

export const duplicateTestList = {
  0: "N",
  1: "Y(O)",
  2: "Y(D)",
};

export const wifiStatus = {
  0: "No",
  1: "Yes",
  2: "Failed to connect",
};

export const getAnomalyMsg = (anomalies) => {
  const anomalyMsg = {
    0: "0 pCi/L radon value detected. Apparent detector malfunction. A retest with another monitor is recommended",
    1: "Same radon value continuously detected. Please review it carefully and make your decision for the next steps.",
    2: "Radon values have increased(decreased) significantly. Apparent detector malfunction. A retest with another monitor is recommended.",
    3: "Apparent detector malfunction or detector is exposed to its maximum measurement range. A retest with another monitor is recommended.",
  };

  const indexOfAllAnomalies = anomalies.reduce(
    (acc, el, i) => (el === 1 ? [...acc, i] : acc),
    []
  );

  return indexOfAllAnomalies.map((index) => anomalyMsg[index]);
};

export const statesFullName = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  IA: "Iowa",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  MA: "Massachusetts",
  MD: "Maryland",
  ME: "Maine",
  MI: "Michigan",
  MN: "Minnesota",
  MO: "Missouri",
  MS: "Mississippi",
  MT: "Montana",
  NC: "North Carolina",
  ND: "North Dakota",
  NE: "Nebraska",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NV: "Nevada",
  NY: "New York",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VA: "Virginia",
  VT: "Vermont",
  WA: "Washington",
  WI: "Wisconsin",
  WV: "Wyoming",
  WY: "West Virginia",
};

export const reportResultSU = {
  0: "≥EPA Guidelines",
  1: "<EPA Guidelines",
};
