import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import _ from "lodash";
import { useLocation, useHistory } from "react-router-dom";
import { Typography, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper } from "@material-ui/core";
import { enqueueSnackbar } from "notistack";
import Loading from "../components/Loading";
import MyRadonInterface from "../../integration/internal/MyRadon/MyRadonInterface";
import moment from "moment";
import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    h6: {
      fontSize: "1rem",
    },
  },
});

const Container = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 0 0 0 0;
`;

const StyledTableCell = styled(TableCell)`
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    font-size: 12px;
    @media (max-width: 400px) {
      font-size: 11px;
    }
    @media (min-width: 400px) and (max-width: 600px) {
      font-size: 12px;
    }
    @media (min-width: 600px) {
      font-size: 14px;
    }
  `}
`;

// Table Row Style
const StyledTableRow = styled(TableRow)`
  & > td {
    font-size: inherit;
  }
  
    ${({ theme }) => css`
    font-size: 11px;
    @media (max-width: 400px) {
      font-size: 11px;
    }
    @media (min-width: 400px) and (max-width: 600px) {
      font-size: 12px;
    }
    @media (min-width: 600px) {
      font-size: 14px;
    }
  `}
  
  height: 50px;
`;

const FirstColumnCell = styled(StyledTableCell)`
  color: #333;
`;

const SecondColumnCell = styled(StyledTableCell)`
  color: #333;
`;

const ThirdColumnCell = styled(StyledTableCell)`
  color: #333;
`;

const StyledTableHeadCell = styled(StyledTableCell)`
  font-weight: bold;
`;

// Table Header Style
const StyledTableSortLabel = styled(TableSortLabel)`
  .MuiTableSortLabel-icon {
    ${({ theme }) => css`
      font-size: 1.2rem; /* 기본 크기 */

      @media (max-width: 400px) {
        font-size: 0.8rem; /* 화면이 작을 때 작게 */
      }
      @media (min-width: 400px) and (max-width: 600px) {
        font-size: 1rem;
      }
      @media (min-width: 600px) {
        font-size: 1.2rem;
      }
    `}
  }
`;

const ReportList = () => {
  const location = useLocation();
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: "requested", direction: "desc" });

  let { email, reportId } = location.state || {};
  email = email || localStorage.getItem("0xaccountId");
  reportId = reportId || "MRR-1742012457";

  useEffect(() => {
    const loadReportList = async () => {
      try {
        setLoading(true);
        const res = await MyRadonInterface.getMyRadonList(email);
        const data = _.get(res, "data", []);
        // if (!data.length) throw new Error("No data available");

        // Sort by "requested" field by default in descending order
        const sortedData = _.orderBy(data, ['requested'], ['desc']);

        // TEST
        // const sortedData = _.orderBy(data, ['requested'], ['desc']).slice(0, 3)

        setTableData(sortedData);
      } catch (error) {
        enqueueSnackbar(`Error fetching report details: ${error.message}`, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    loadReportList();
  }, [email, reportId]);

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });

    // Sort the data when the sorting criteria change
    setTableData(prevData => {
      return _.orderBy(prevData, [key], [direction]);
    });
  };

  const handleRowClick = (reportId) => {
    history.replace('/report', { email, reportId });
  };

  if (loading) return <Loading />;

  return (
    <Container>
      {tableData.length > 0 ? (
        <Card style={{
          background: "white",
          minHeight: "calc(83vh)",
        }}>
          <CardContent style={{ flexGrow: 1, display: "flex", flexDirection: "column",
            paddingTop: 3,
            paddingBottom: 3,
            paddingLeft: 0,
            paddingRight: 0,
            margin: 0
          }}>
            <TableContainer component={Paper} style={{ flexGrow: 1 }}>
              <Table style={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    {/*<StyledTableHeadCell align="center" style={{ width: "30%", backgroundColor: "red" }}>*/}
                    <StyledTableHeadCell align="center" style={{ width: "30%"}}>
                      <StyledTableSortLabel
                        active={sortConfig.key === "device_name"}
                        direction={sortConfig.direction}
                        onClick={() => handleSort("device_name")}
                      >
                        Device
                      </StyledTableSortLabel>
                    </StyledTableHeadCell>
                    {/*<StyledTableHeadCell align="center" style={{ width: "44%", backgroundColor: "blue"  }}>*/}
                    <StyledTableHeadCell align="center" style={{ width: "44%"}}>
                      <StyledTableSortLabel
                        active={sortConfig.key === "requested"}
                        direction={sortConfig.direction}
                        onClick={() => handleSort("requested")}
                      >
                        Requested
                      </StyledTableSortLabel>
                    </StyledTableHeadCell>
                    {/*<StyledTableHeadCell align="center" style={{ width: "26%", backgroundColor: "yellow"  }}>*/}
                    <StyledTableHeadCell align="center" style={{ width: "26%"}}>
                      <StyledTableSortLabel
                        active={sortConfig.key === "processed"}
                        direction={sortConfig.direction}
                        onClick={() => handleSort("processed")}
                      >
                        Completed
                      </StyledTableSortLabel>
                    </StyledTableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map(({ rpt_id, device_name, requested, processed }) => (
                    <StyledTableRow key={rpt_id} hover onClick={() => handleRowClick(rpt_id)}>
                      <FirstColumnCell align="center" >
                        {device_name}
                      </FirstColumnCell>
                      <SecondColumnCell align="center" >
                        {moment.unix(requested).format("MM/DD/YY HH:mm:ss")}
                      </SecondColumnCell>
                      <ThirdColumnCell align="center" >
                        {processed}
                      </ThirdColumnCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>

      ) : (
        <Card>
          <CardContent>
            <Typography variant="h6" style={{ textAlign: "center", color: "#808080", fontSize: "13px" }}>
              {email ? "There are no completed reports generated from this email account." : "There's no passed email. Please try again."}
            </Typography>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default ReportList;
